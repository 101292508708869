import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    label: String,
    labels: Array,
    data: Array,
    options: Object,
    axis: String,
    dataLabel: Boolean,
    disableYAxis: Boolean
  }

  connect () {
    this.myDataLabel = this.dataLabelValue || false

    import("chart.js/auto").then(({ default: Chart }) => {
      import("chartjs-plugin-datalabels").then(({ default: ChartDataLabels }) => {
        const data = {
          labels: this.labelsValue,
          datasets: [{
            label: this.labelValue,
            backgroundColor: 'rgba(180, 0, 47, 1)',
            borderColor: 'rgba(180, 0, 47, 1)',
            data: this.dataValue,
            datalabels: {
              display: this.myDataLabel,
              anchor: 'end',
              align: 'top',
              clamp: true,
              font: {
                size: "28px",
                weight: 700,
                color: "#3C3C3C"
              },
              offset: 3,
              formatter: function (value) {
                return value + "%"
              }
            }
          }]
        }

        const config = {
          type: "bar",
          data: data,
          plugins: [ChartDataLabels],
          options: {
            indexAxis: this.axisValue,
            categoryPercentage: 1.0,
            barPercentage: 0.95,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return context.formattedValue + '%'
                  }
                }
              }
            },
            layout: {
              padding: {
                top: 40
              }
            },
            scales: {
              x: {
                grid: {
                  display: false
                }
              },
              y: {
                display: this.disableYAxisValue,
                grid: {
                  display: false
                }
              }
            }
          }
        }

        this.chart = new Chart(this.element, config)
      })
    })
  }

  disconnect () {
    this.chart.destroy()
    this.chart = undefined
  }
}
